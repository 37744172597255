<!--
 * @Author: 前胡
 * @LastEditors: 矢车
 * @Date: 2020-03-03 17:30:54
 * @LastEditTime: 2020-11-04 09:32:56
 * @Description: 寄样管理 - 寄样申请列表
 -->
<template>
  <div id="sampleApply">
    <SampleApplySearchBar
    v-auth="'samples_manage_apply_list'"
      @callback="sampleApplySearchCallback"
      :searchParams="searchParams"
      :loading="listLoading"
      :exporting="exporting"
    @handleExportSampleApplyList="handleExportSampleApplyList"
    />
   <!-- <div class="option-btns">
      <a-button
        v-auth="'samples_manage_export'"
        type="primary"
        class="option-btn"
        html-type="submit"
        @click="handleExportSampleApplyList()"
        :loading="exporting"
      >导出</a-button>
      <a-button
        v-auth="'samples_manage_import'"
        type="primary"
        class="option-btn"
        html-type="submit"
        @click="handleShowImportExpressModal()"
      >导入物流信息</a-button>
    </div>-->
    <a-table
      :scroll="{ x: 1200, y: tableScrollY }"
      v-auth="'samples_manage_apply_list'"
      :rowKey="record => record.id"
      :columns="sampleApplyColumns"
      :dataSource="list"
      :pagination="pagination"
      :loading="listLoading"
      :locale="{ emptyText: '暂无申请' }"
      @change="handleTableChange"
    >
      <template slot="level" slot-scope="record">
        <a-tag color="green">{{ record.level?'LV' + record.level:'-' }}</a-tag>
      </template>
      <template slot="payStatus" slot-scope="record">
        <a-tag :color="payStatusFormat(record).color || 'red'">{{payStatusFormat(record).label || '--'}}</a-tag>
      </template>
      <template slot="start_time" slot-scope="record">
        {{record.start_time || '--'}}
      </template>
      <template slot="expressStatus" slot-scope="record">
        <a-tag v-if="record.send_status===1" color="purple">待发货</a-tag>
        <a-tag v-if="record.send_status===2" color="blue">已发货</a-tag>
        <a-tag v-if="record.send_status===3" color="green">已收货</a-tag>
        <a-tag v-if="record.send_status===4" color="orange">已取消</a-tag>
        <a-tag v-if="record.send_status===5" color="red">未通过</a-tag>
        <a-tag v-if="record.send_status===6" color="#2db7f5">待寄回</a-tag>
        <a-tag v-if="record.send_status===7" color="cyan">已寄出</a-tag>
        <a-tag v-if="record.send_status===8" color="purple">已回收</a-tag>
      </template>
      <template slot="krSource" slot-scope="record">
        <a-tag v-if="record.kurangoods__source===1" color="red">自主招商</a-tag>
        <a-tag v-if="record.kurangoods__source===2" color="green">淘宝联盟</a-tag>
        <a-tag v-if="record.kurangoods__source===3" color="purple">大淘客</a-tag>
        <a-tag v-if="record.kurangoods__source===4" color="cyan">抖音小店</a-tag>
        <a-tag v-if="record.kurangoods__source===5" color="orange">招商系统</a-tag>
      </template>
      <template slot="isRecycleStatus" slot-scope="record">
        <a-tag v-if="record.is_recycle===0" color="red">否</a-tag>
        <a-tag v-if="record.is_recycle===1" color="green">是</a-tag>
      </template>
      <template slot="examineStatus" slot-scope="record">
        <a-tag v-if="record.examine_status===1" color="purple">未审核</a-tag>
        <a-tag v-if="record.examine_status===2" color="green">已通过</a-tag>
        <a-tag v-if="record.examine_status===3" color="red">未通过</a-tag>
        <a-tag v-if="record.examine_status===4">已取消</a-tag>
      </template>
      <template slot="speical" slot-scope="record">
        {{record.special ? record.special : '-'}}
      </template>
      <template slot="liveType" slot-scope="record">
        <a-tag v-if="record.apply_type===1" color="blue">拼播</a-tag>
        <a-tag v-if="record.apply_type===2" color="cyan">单播</a-tag>
        <a-tag v-if="record.apply_type===3" color="blue">专场</a-tag>
      </template>
      <template slot="operation" slot-scope="record">
        <a-button
          v-auth="'samples_manage_audit'"
          v-if="record.examine_status===1 && record.is_buy_order!==1"
          type="primary"
          class="mr-20"
          size="small"
          @click="handleShowSamplyApplyAudit(record)"
        >审核</a-button>
        <a-button size="small" @click="handleShowApplyDetail(record)">查看</a-button>
      </template>
    </a-table>
    <!-- 导入物流信息弹层 -->
    <ModalImportExpressFile
      v-if="importExpressDialogVisible"
      @callback="importExpressModalCallback"
    />

    <!-- 寄样申请审核弹层 -->
    <ModalSampleApplyAudit
      v-if="sampleApplyDialogVisible"
      :id="tempApplyId"
      @callback="sampleApplyAuditCallback"
    />

    <!--申请单详情-->
    <a-drawer
      :title="applyDetailDrawerTitle"
      placement="right"
      width="1050"
      @close="handleSampleApplyDetailDrawerClose"
      :visible="applyDetailDrawerVisiable"
    >
      <ApplyDetail
        :id="tempApplyId"
        v-if="applyDetailDrawerVisiable"
        @callback="handleSampleApplyDetailDrawerClose"
      />
    </a-drawer>
  </div>
</template>

<script>
import { getSampleList, exportSampleData } from "@/service/sample";
import ApplyDetail from "@/components/admin/sample/apply-detail";
import ModalSampleApplyAudit from "@/components/admin/sample/modal-sample-apply-audit";
import ModalImportExpressFile from "@/components/admin/sample/modal-import-express-file";
import SampleApplySearchBar from "@/components/admin/sample/sample-apply-search-bar";
import { checkPer } from "@/utils/index";
import { payStatusOptions } from "@/const/goods-cate"


// 寄样申请列表
const sampleApplyColumns = [
  {
    title: "申请单号",
    fixed: 'left',
    dataIndex: "no",
    align: "center",
    width: 110
  },
  {
    title: "申请人",
    dataIndex: "nickname",
    align: "center",
    width: 100
  },
  {
    title: "主播等级",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "level" }
  },
  {
    title: "商品名称",
    dataIndex: "good.item_name",
    align: "left",
    width: 200
  },
  {
    title: "支付状态",
    scopedSlots: { customRender: "payStatus" },
    align: "center",
    width: 110
  },
  // {
  //   title: "商品来源",
  //   align: "center",
  //   width: 100,
  //   scopedSlots: { customRender: "krSource" }
  // },
  {
    title: "发货状态",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "expressStatus" }
  },
  {
    title: "审核状态",
    align: "center",
    width: 110,
    scopedSlots: { customRender: "examineStatus" }
  },
  // {
  //   title: "样品回收",
  //   align: "center",
  //   width: 100,
  //   scopedSlots: { customRender: "isRecycleStatus" }
  // },
  // {
  //   title: "专场",
  //   align: "center",
  //   width: 100,
  //   scopedSlots: { customRender: "speical" }
  // },
  {
    title: "直播类型",
    width: 100,
    scopedSlots: { customRender: "liveType" }
  },
  {
    title: "开播时间",
    scopedSlots: { customRender: "start_time" },
    align: "center",
    width: 160
  },
  {
    title: "申请时间",
    dataIndex: "apply_time",
    align: "center",
    width: 160
  },
  {
    title: "操作",
    align: "center",
    width: 160,
    fixed: 'right',
    scopedSlots: { customRender: "operation" }
  }
];

export default {
  name: "SampleApply",
  components: {
    ApplyDetail,
    ModalSampleApplyAudit,
    ModalImportExpressFile,
    SampleApplySearchBar
  },
  data() {
    return {
      tableScrollY: 600,
      form: this.$form.createForm(this),
      payStatusOptions,  //  支付状态枚举
      sampleApplyColumns,
      // 寄样详情drawer状态
      applyDetailDrawerVisiable: false,
      // 导入物流信息弹层状态
      importExpressDialogVisible: false,
      // 审核弹层状态
      sampleApplyDialogVisible: false,
      listLoading: true,
      exporting: false,
      list: [],
      searchParams: {
        search: "",
        status: undefined,
        examine_status: undefined,
        apply_type: undefined,
        pay_status: undefined,
        is_buy_order: undefined,
        time_type: undefined,
        time1: undefined,
        time2: undefined,
        good__kurangoods__source: undefined,
        special__isnull: undefined,
        goods_channel: undefined,
        is_kuran_selected: undefined
      },
      pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        showTotal: total =>
          `共 ${total} 条记录 第 ${this.pagination.current}/${Math.ceil(
            total / this.pagination.pageSize
          )} 页`,
        showQuickJumper: true,
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) =>
          (this.pagination.pageSize = pageSize)
      },
      // 临时寄样申请id
      tempApplyId: "",
      // 右侧drawer
      applyDetailDrawerTitle: "寄样申请"
    };
  },
  created() {
    var windowHeight = document.documentElement.clientHeight || 1000;
    this.tableScrollY = windowHeight - 330;
  },
  mounted() {
    this.getSampleApplyList();
  },
  methods: {
    payStatusFormat(record) {
      for(const val of this.payStatusOptions){
        if(val.value == record.pay_status){
          return val
        }
      }
      return '--'
    },
    // 隐藏编辑drawer
    handleSampleApplyDetailDrawerClose(isNeedFresh = false) {
      this.applyDetailDrawerVisiable = false;
      if (isNeedFresh) {
        this.getSampleApplyList();
      }
    },
    // 导出
    async handleExportSampleApplyList() {
      const data = Object.assign({}, this.searchParams);
      this.exporting = true;
      const { err, res } = await exportSampleData(data);
      this.exporting = false;
      if (!err) {
        if (res.success) {
          window.open(res.data.detail_url);
        } else {
          this.$message.warning(res.message);
        }
      }
    },
    // 获取寄样申请列表
    async getSampleApplyList() {
      if (!checkPer("samples_manage_apply_list")) {
        this.$message.error("无权限获取列表");
        return false;
      }
      const data = Object.assign(
        { page: this.pagination.current, page_size: this.pagination.pageSize },
        this.searchParams
      );
      this.listLoading = true;
      const { err, res } = await getSampleList(data);
      this.listLoading = false;
      if (!err) {
        if (res.success) {
          const pagination = { ...this.pagination };
          this.list = res.data.results;
          pagination.total = res.data.count;
          this.pagination = pagination;
        } else {
          this.$message.warning(res.message);
        }
      }
    },
    // 表格分页
    handleTableChange(pagination) {
      if (pagination) this.pagination.current = pagination.current;
      this.getSampleApplyList();
    },
    // 点击查看按钮
    handleShowApplyDetail(record) {
      this.applyDetailDrawerVisiable = true;
      this.applyDetailDrawerTitle = `寄样申请 / ${record.no}`;
      this.tempApplyId = record.id;
    },
    // 显示审核弹层
    handleShowSamplyApplyAudit(record) {
      this.tempApplyId = record.id;
      this.sampleApplyDialogVisible = true;
    },
    // 寄样申请审核回调
    sampleApplyAuditCallback(isNeedRefreshList = false) {
      this.sampleApplyDialogVisible = false;
      if (isNeedRefreshList) {
        this.getSampleApplyList();
      }
    },
    // 显示导入物流信息弹层
    handleShowImportExpressModal() {
      this.importExpressDialogVisible = true;
    },
    // 导入物流信息回调
    importExpressModalCallback(isNeedRefreshList = false) {
      this.importExpressDialogVisible = false;
      if (isNeedRefreshList) {
        this.pagination.current = 1;
        this.getSampleApplyList();
      }
    },
    // 搜索查询按钮
    sampleApplySearchCallback(res) {
      this.pagination.current = 1;
      this.searchParams = Object.assign({}, res);
      this.getSampleApplyList();
    }
  }
};
</script>
<style lang='less' scoped>
  #sampleApply{
    /deep/ .ant-table-body {
      //样式穿透
      overflow-x: auto !important;
    }
    .ant-table-body {
      //样式穿透
      overflow-x: auto !important;
    }
  }

.option-btns {
  margin-bottom: 20px;
  margin-top: 10px;
  .option-btn {
    margin-right: 10px;
  }
}
.mr-20 {
  margin-right: 20px;
}
</style>
