<!--
 * @Description: 修改物流信息
 * @Author: 前胡
 * @LastEditors: 前胡
 * @Date: 2020-03-13 10:56:09
 * @LastEditTime: 2020-04-13 16:22:46
 -->
 <template>
  <div class="express-detail-modal">
    <a-modal
      title="物流详情"
      :footer="null"
      v-model="editExpressModalVisible"
      @cancel="handleHideThisModal"
    >
      <div v-if="loading" class="spin-box">
        <a-spin />
      </div>
      <div v-if="!loading">
        <ul class="express-list" v-if="list.length">
          <li class="express-item" v-for="i in list" :key="i">
            <p>{{i.time}}</p>
            <div>{{i.context}}</div>
          </li>
        </ul>
        <a-alert v-if="!list.length" message="暂无物流信息" type="info" />
      </div>
    </a-modal>
  </div>
</template>
<script>
import { getExpressDetailData } from "@/service/sample";
export default {
  name: "ExpressDetailModal",
  props: {
    id: {
      default: ""
    }
  },
  data() {
    return {
      editExpressModalVisible: true,
      list: [],
      loading: false
    };
  },
  created() {
    this.getExpressDetailData();
  },
  methods: {
    // 获取物流信息
    async getExpressDetailData() {
      this.loading = true;
      const { err, res } = await getExpressDetailData(this.id);
      this.loading = false;
      if (!err && res.success) {
        this.list = res.data.data;
      }
    },
    // 隐藏modal
    handleHideThisModal(isNeedParent = false) {
      this.$emit("callback", isNeedParent);
    }
  }
};
</script>
<style lang="less" scoped>
.express-list {
  margin: 0;
  padding: 10px;
  .express-item {
    margin-bottom: 15px;
    p {
      margin-bottom: 5px;
      color: #999;
    }
  }
}
.spin-box {
  text-align: center;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
}
</style>