<!--
 * @Author: 前胡
 * @LastEditors: 矢车
 * @Date: 2020-03-05 15:14:50
 * @LastEditTime: 2020-11-04 09:32:39
 * @Description: 寄样管理 - 寄样申请列表搜索条
 -->
<template>
  <a-form class="filter-condition-form" layout="inline" :form="form" >
    <a-form-item>
      <a-input
        v-model="searchParams.search"
        style="width: 580px;"
        placeholder="商品名称/商品ID/注册手机/收货人姓名/收货人手机/申请单号/专场ID/主播昵称/供货商"
        allowClear
      />
    </a-form-item>
    <!--<a-form-item>
      <a-select style="width: 120px" placeholder="商品渠道" v-model="searchParams.goods_channel" allowClear>
        <a-select-option v-for="item in goodsChannelOptions" :key="item.value">{{item.label}}</a-select-option>
      </a-select>
    </a-form-item>-->
    <a-form-item>
      <a-select style="width: 120px" placeholder="折一优选" v-model="searchParams.is_kuran_selected" allowClear>
        <a-select-option key="1">是</a-select-option>
        <a-select-option key="0">否</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item>
      <a-select style="width: 120px" placeholder="寄样模式" v-model="searchParams.is_buy_order" allowClear>
        <a-select-option v-for="item in sendSampleTwoOtherOptionsOther" :key="item.value">{{item.label}}</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item>
      <a-select style="width: 120px" placeholder="支付状态" v-model="searchParams.pay_status" allowClear>
        <a-select-option v-for="item in payStatusOptions" :key="item.value">{{item.label}}</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item>
      <a-select style="width: 120px" placeholder="发货状态" v-model="searchParams.send_status" allowClear>
        <a-select-option v-for="item in expressStatusOptions" :key="item.value">{{item.label}}</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item>
      <a-select style="width: 120px" placeholder="审核状态" v-model="searchParams.examine_status" allowClear>
        <a-select-option v-for="item in auditStatusOptions" :key="item.value">{{item.label}}</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item>
      <a-select style="width: 120px" placeholder="直播类型" v-model="searchParams.apply_type" allowClear>
        <a-select-option v-for="item in liveTypeOptions" :key="item.value">{{item.label}}</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item>
      <a-select style="width: 120px" placeholder="样品回收" v-model="searchParams.is_recycle" allowClear>
        <a-select-option v-for="item in sampleRecycleOptions" :key="item.value">{{item.label}}</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item>
      <a-select style="width: 120px" placeholder="商品来源" v-model="searchParams.good__kurangoods__source" allowClear>
        <a-select-option v-for="item in sourceOptions" :key="item.value">{{item.label}}</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item>
      <a-select style="width: 120px" placeholder="时间类型" v-model="searchParams.time_type" allowClear>
        <a-select-option v-for="item in dateTypeOptions" :key="item.value">{{item.label}}</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item>
      <a-range-picker @change="onChangeDate"  />
    </a-form-item>
    <a-form-item>
      <a-button type="primary" html-type="submit" :loading="loading" @click="handleFormSubmit">查询</a-button>
      <a-button
        v-auth="'samples_manage_export'"
        type="primary"
        class="option-btn"
        style="margin-left: 10px"
        html-type="submit"
        @click="handleExportSampleApplyList()"
        :loading="exporting"
      >导出</a-button>
    </a-form-item>
  </a-form>
</template>
<script>
import {sourceOptions,sendSampleTwoOtherOptionsOther,payStatusOptions } from '@/const/goods-cate.js'
// 发货状态options
const expressStatusOptions = [
  { label: "待发货", value: "1" },
  { label: "已发货", value: "2" },
  { label: "已收货", value: "3" },
  { label: "已取消", value: "4" },
  { label: "待寄回", value: "6" },
  { label: "已寄出", value: "7" },
  { label: "已回收", value: "8" },
];

// 审核状态options
const auditStatusOptions = [
  { label: "待审核", value: "1" },
  { label: "已通过", value: "2" },
  { label: "未通过", value: "3" },
  { label: "已取消", value: "4" },
];

// 直播类型options
const liveTypeOptions = [
  { label: "单播", value: "2" },
  { label: "拼播", value: "1" },
  { label: "专场", value: "3" },
];

// 时间类型options
const dateTypeOptions = [
  { label: "申请时间", value: "1" },
  { label: "开播时间", value: "2" }
];

// 样品回收options
const sampleRecycleOptions = [
  { label: "是", value: 1 },
  { label: "否", value: 0 }
]

// 商品渠道
const goodsChannelOptions = [
  { label: "淘宝联盟", value: 1 },
  { label: "抖音小店", value: 2 }
]
export default {
  name: "SampleApplySearchBar",
  props: {
    searchParams: {
      type: Object,
      default: function() {
        return {};
      }
    },
    loading: {
      default: false,
      type: Boolean
    },
    exporting: {
      default: false,
      type: Boolean
    },
  },
  data() {
    return {
      // searchTypeOptions,
      sourceOptions,
      sendSampleTwoOtherOptionsOther,
      payStatusOptions,
      expressStatusOptions,
      auditStatusOptions,
      liveTypeOptions,
      dateTypeOptions,
      sampleRecycleOptions,
      goodsChannelOptions,
      form: this.$form.createForm(this)
    };
  },
  methods: {
    handleFormSubmit(e) {
      e.preventDefault();
      this.form.validateFields(err => {
        if (!err) {
          this.$emit("callback", this.searchParams);
        }
      });
    },
    handleExportSampleApplyList(){
      this.$emit("handleExportSampleApplyList");
    },
    // 时间段选择
    onChangeDate(date, dateString) {
      this.searchParams.time1 = dateString[0];
      this.searchParams.time2 = dateString[1];
    }
  }
};
</script>
<style lang='less' scoped>
</style>
