/*
 * @Description: 物流列表
 * @Author: 前胡
 * @LastEditors: 前胡
 * @Date: 2020-04-01 14:00:40
 * @LastEditTime: 2020-04-01 14:24:57
 */
export const expressList = [
    {'text': '邮政快递包裹', 'value': 'youzhengguonei'},
    {'text': '韵达快递', 'value': 'yunda'},
    {'text': '中通快递', 'value': 'zhongtong'},
    {'text': '圆通速递', 'value': 'yuantong'},
    {'text': '百世快递', 'value': 'huitongkuaidi'},
    {'text': '申通快递', 'value': 'shentong'},
    {'text': '顺丰速运', 'value': 'shunfeng'},
    {'text': '京东物流', 'value': 'jd'},
    {'text': 'EMS', 'value': 'ems'},
    {'text': '天天快递', 'value': 'tiantian'},
    {'text': '邮政标准快递', 'value': 'youzhengbk'},
    {'text': '德邦', 'value': 'debangwuliu'},
    {'text': '宅急送', 'value': 'zhaijisong'},
    {'text': '德邦快递', 'value': 'debangkuaidi'},
    {'text': '魔速达', 'value': 'mosuda'},
    {'text': '优速快递', 'value': 'youshuwuliu'},
    {'text': '安能快递', 'value': 'ane66'}
]
