<!--
 * @Description: 修改收货地址弹层
 * @Author: 前胡
 * @LastEditors: 前胡
 * @Date: 2020-03-13 10:56:09
 * @LastEditTime: 2020-03-18 16:07:12
 -->
 <template>
  <div class="user-other-address-modal">
    <a-modal title="其他地址" v-model="isVisable" width="600px" @cancel="handleHideThisModal">
      <a-form :form="userAddressForm">
        <a-form-item label="收货人" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          <a-input
            style="width:150px;"
            v-decorator="['postData.realname', { rules: [{ required: true, message: '请输入收货人' }] }]"
          />
        </a-form-item>
        <a-form-item label="手机" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          <a-input
            style="width:150px;"
            v-decorator="[
                'postData.mobile',
                {
                  rules: [
                    { required: true, message: '请输入手机号' },
                    { validator: checkPhoneNumber }
                  ],
                  validateFirst: true
                }
              ]"
          />
        </a-form-item>
        <a-form-item label="详细地址" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          <CityPicker @callback="handleChangeAddress" :cityData="cityData" />
          <a-textarea
            :autosize="{ minRows: 2, maxRows: 6 }"
            v-decorator="['postData.address', { rules: [{ required: true, message: '请输入详细地址' }] }]"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <a-button key="back" @click="handleHideThisModal">取消</a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="isPosting"
          @click="handleSubmitOtherAddrData"
        >确定</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import { getAddressList } from "@/service/user";
import { patchSample } from "@/service/sample";
import { checkPhoneNumber } from "@/utils/validator";
import CityPicker from "@/components/admin/city-picker";
export default {
  name: "UserOtherAddressModal",
  components: {
    CityPicker
  },
  props: {
    id: {
      default: ""
    },
    sampleid: {
      default: ""
    }
  },
  data() {
    return {
      checkPhoneNumber,
      isVisable: true,
      list: [],
      defaultId: null,
      userAddressForm: this.$form.createForm(this),
      cityData: {
        provinceId: 110000,
        cityId: 110000,
        areaId: 110101,
        provinceStr: "北京市",
        cityStr: "北京市",
        areaStr: "东城区"
      },
      isPosting: false,
      postData: {
        province: "北京市",
        city: "北京市",
        area: "东城区",
        address: "",
        mobile: "",
        realname: "",
        province_id: 110000,
        city_id: 110000,
        district_id: 110101
      }
    };
  },
  created() {
    this.getAddressListData();
  },
  methods: {
    // 获取地址列表
    async getAddressListData() {
      const { err, res } = await getAddressList({ user: this.id });
      if (!err) {
        if (res.success) {
          this.list = res.data.results;
          this.getDefaultId(res.data.results);
        }
      }
    },
    // 获取默认选中id
    getDefaultId(list) {
      const defaultIds = list.filter(i => i.is_default === true);
      const defaultId = defaultIds[0] && defaultIds[0].id;
      this.defaultId = defaultId;
    },
    // 获取地址信息
    handleChangeAddress(e) {
      this.postData.province = e.provinceStr;
      this.postData.city = e.cityStr;
      this.postData.area = e.areaStr;
      this.postData.province_id = e.provinceId;
      this.postData.city_id = e.cityId;
      this.postData.district_id = e.areaId;
    },
    // 提交数据
    handleSubmitOtherAddrData() {
      this.userAddressForm.validateFields(async (err, values) => {
        if (!err) {
          const data = Object.assign(this.postData, values.postData);
          this.isPosting = true;
          const { err, res } = await patchSample(this.sampleid, data);
          this.isPosting = false;
          if (!err) {
            this.$message.success(res.message);
            this.handleHideThisModal(true)
          }
        }
      });
    },
    // 隐藏modal
    handleHideThisModal(isNeedParent=false){
      this.$emit('callback', isNeedParent)
    }
  }
};
</script>
<style lang="less" scoped>
.user-other-address-modal {
  width: 800px;
}
.address-list {
  margin: 0;
  padding: 0;
  list-style: none;
  .address-item {
    margin-bottom: 10px;
    .ai-name {
      width: 150px;
      display: inline-block;
    }
    .ai-phone {
      width: 120px;
      display: inline-block;
    }
    .ai-address {
    }
  }
}
</style>