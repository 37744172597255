<!--
 * @Description: 修改物流信息
 * @Author: 前胡
 * @LastEditors: 矢车
 * @Date: 2020-03-13 10:56:09
 * @LastEditTime: 2020-10-27 09:57:39
 -->
 <template>
  <div class="apply-express-modal">
    <a-modal title="编辑物流" v-model="editExpressModalVisible" @cancel="handleHideThisModal">
      <a-form :form="editExpressForm">
        <a-form-item label="物流公司" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          <a-select
            style="width:350px;"
            allowClear
            showSearch
            placeholder="请选择物流公司"
            optionFilterProp="children"
            v-decorator="['express_company', { rules: [{ required: true, message: '请输入物流公司' }] }]"
            @focus="handleFocus"
            @blur="handleBlur"
            @change="handleChange"
            :filterOption="filterOption"
          >
            <a-select-option :value="i.value" v-for="i in expressList" :key="i.value">{{i.text}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="快递单号" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          <a-input
            style="width:350px;"
            v-decorator="['express_no', { rules: [{ required: true, message: '请输入快递单号' },{ validator: checkExpressNo }] }]"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <a-button key="back" @click="handleHideThisModal">取消</a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="isPosting"
          @click="handleApplyExpressData"
        >确定</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import { expressPackage } from "@/service/sample";
import { checkExpressNo } from "@/utils/validator";
import { expressList } from "@/const/express";
export default {
  name: "ApplyExpressModal",
  props: {
    id: {
      default:''
    },
    data: {
      default: function(){
        return {}
      }
    }
  },
  data() {
    return {
      checkExpressNo,
      expressList,
      editExpressForm: this.$form.createForm(this),
      editExpressModalVisible: true
    };
  },
  mounted(){
    this.$nextTick(() => {
      this.editExpressForm.setFieldsValue({
        "express_company": this.data && this.data.com,
        "express_no": this.data && this.data.nu,
      });
    });
  },
  methods: {
    // 提交数据
    handleApplyExpressData() {
      this.editExpressForm.validateFields(async (err, values) => {
        if (!err) {
          this.isPosting = true;
          // values.send_status = 2;
          const { err, res } = await expressPackage(this.id, values);
          this.isPosting = false;
          if (!err) {
            this.$message.success(res.message);
            this.handleHideThisModal(true);
          }
        }
      });
    },
    // 隐藏modal
    handleHideThisModal(isNeedParent = false) {
      this.$emit("callback", isNeedParent);
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    }
  }
};
</script>
<style lang="less" scoped>
.user-other-address-modal {
  width: 800px;
}
.address-list {
  margin: 0;
  padding: 0;
  list-style: none;
  .address-item {
    margin-bottom: 10px;
    .ai-name {
      width: 150px;
      display: inline-block;
    }
    .ai-phone {
      width: 120px;
      display: inline-block;
    }
    .ai-address {
    }
  }
}
</style>