/*
 * @Description: 寄样相关接口
 * @Author: 前胡
 * @LastEditors: 矢车
 * @Date: 2020-03-07 16:16:34
 * @LastEditTime: 2020-10-27 09:58:14
 */
import axios from './axios'

// 寄样申请列表
export const getSampleList = params => {
    return axios.get('/api/operators/v1/admin-sample-apply/', {
        params
    })
}

// 寄样申请详情
export const getSampleDetail = id => {
    return axios.get(`/api/operators/v1/admin-sample-apply/${id}/`)
}

// 编辑/审核寄样申请
export const patchSample = (id, data) => {
    return axios.patch(`/api/operators/v1/admin-sample-apply/${id}/`, data)
}

// 导出寄样申请表
export const exportSampleData = params => {
    return axios.get(`/api/operators/v1/sample/export-file/`, {params})
}

// 编辑物流信息
export const expressPackage = (id,data) => {
  return axios.patch(`/api/operators/v1/package/${id}/`,data)
}

// 获取物流详细信息
export const getExpressDetailData = id => {
    return axios.get(`/api/v1/express/${id}/`)
}

// 后台确认收货
export const expressCheck = data => {
    return axios.post(`/api/operators/v1/express/check/`, data)
}

// 获取直播排期列表
export const getLiveList = (params) => {
  return axios.get('/api/operators/v1/live-group-apply/', { params });
};

// 获取排期管理列表
export const getLiveManagementList = (params) => {
  return axios.get('/api/operators/v2/submit/kuran-goods-events/', { params });
};

//新增直播排期
export const postNewLive = data => {
  return axios.post(`/api/operators/v2/submit/kuran-goods-events/`, data)
}

//新增直播排期
export const endSelectGoodLive = (id , data) => {
  return axios.patch(`/api/operators/v2/submit/kuran-goods-events/${id}/`, data)
}

// 获取直播排期各状态数量
export const getLiveStatisticsData = () => {
  return axios.get('/api/operators/v1/livegroupapply-statistics/');
};

// 单个更新直播排期状态
export const updateLiveStatus = (id, params) => {
  return axios.patch(`/api/operators/v1/live-group-apply/${id}/`, params);
};

// 批量更新直播排期状态
export const batchUpdateLiveStatus = (params) => {
  return axios.post(`/api/operators/v1/batch-update-livegroupapply-status/`, params);
};

// 申佣处理
export const handleCommission = (params) => {
  return axios.post(`/api/operators/v1/handle-commission/`, params);
};

// 列表导出
export const exportLiveData = (params) => {
  return axios.get('/api/operators/v1/live-group-apply-export/', { params });
};

// 直播资料excel导出记录
export const getExportHistoryList = (params) => {
  return axios.get('/api/operators/v1/export-logs/', { params });
};

// 获取排期详情
export const getScheduleDetail = (id) => {
  return axios.get(`/api/operators/v1/live-group-apply/${id}/`);
};

// 获取排期流转记录
export const getOperateLogs = (params) => {
  return axios.get(`/api/operators/v1/sample-apply-log/`, { params });
};

// 专场报名审核
export const specialAudit = (id, params) => {
  return axios.patch(`/api/operators/v1/special-apply/${id}/`, params);
};

// 专场报名 - 查看详情
export const specialDetail = (id) => {
  return axios.get(`/api/operators/v1/special-apply/${id}/`);
};

