/*
 * @Description: 下载模板地址
 * @Author: 前胡
 * @LastEditors: 矢车
 * @Date: 2020-03-14 15:39:11
 * @LastEditTime: 2020-09-26 14:21:23
 */

const isProd = process.env.targetEnv === 'production';
// const version = '1.6.80';

// 批量新增商品自动获取模板下载地址
export const goodsImportAutoFetchTemplateUrl = isProd
  ? `https://admin.zheyi360.com/api/static/商品导入模板-自动获取.xlsx`
  : `https://admin-test.zheyi360.com/api/static/商品导入模板-自动获取.xlsx`;

// 批量新增商品后台维护模板下载地址
export const goodsImportManualWorkTemplateUrl = isProd
  ? `https://admin.zheyi360.com/api/static/商品导入模板-后台维护.xlsx`
  : `https://admin-test.zheyi360.com/api/static/商品导入模板-后台维护.xlsx`;

// 批量新增商品抖音小店商品模版地址
export const goodsImportDouyinTemplateUrl = isProd
  ? `https://admin.zheyi360.com/api/static/抖音小店商品导入模板.xlsx`
  : `https://admin-test.zheyi360.com/api/static/抖音小店商品导入模板.xlsx`;

// 批量导入物流信息下载地址
export const expressImportTemplateUrl = isProd
  ? 'https://admin.zheyi360.com/api/static/物流信息导入信息.xlsx'
  : 'https://admin-test.zheyi360.com/api/static/物流信息导入信息.xlsx';
