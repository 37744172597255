<!--
 * @Description: 修改物流信息
 * @Author: 前胡
 * @LastEditors: 前胡
 * @Date: 2020-03-13 10:56:09
 * @LastEditTime: 2020-03-23 10:35:08
 -->
 <template>
  <div class="cancel-apply-modal">
    <a-modal title="取消寄样申请" v-model="cancelSampleApplyModalVisible">
      <a-form :form="cancelApplyForm">
        <a-form-item label="" :label-col="{ span: 1 }" :wrapper-col="{ span: 23 }">
          <div class="mb-20">
            <a-alert message="确定要取消该寄样申请？" banner />
          </div>
          <a-textarea
            placeholder="请填写取消原因"
            :autosize="{ minRows: 2, maxRows: 6 }"
            v-decorator="['examine_remark']"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <a-button key="back" @click="handleHideThisModal">取消</a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="isPosting"
          @click="handleCancelApplyData"
        >确定</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import { patchSample } from "@/service/sample";
export default {
  name: "CancelApplyModal",
  props: {
    id: {
      default: ""
    }
  },
  data() {
    return {
      cancelApplyForm: this.$form.createForm(this),
      cancelSampleApplyModalVisible: true
    };
  },
  methods: {
    // 提交数据
    handleCancelApplyData() {
      this.cancelApplyForm.validateFields(async (err, values) => {
        if (!err) {
          const data = values;
          // data.examine_status = 3;
          data.send_status = 4;
          this.isPosting = true;
          const { err, res } = await patchSample(this.id, data);
          this.isPosting = false;
          if (!err) {
            this.$message.success(res.message);
            this.handleHideThisModal(true);
          }
        }
      });
    },
    // 隐藏modal
    handleHideThisModal(isNeedParent = false) {
      this.$emit("callback", isNeedParent);
    }
  }
};
</script>
<style lang="less" scoped>
.mb-20 {
  margin-bottom: 20px;
}
.user-other-address-modal {
  width: 800px;
}
.address-list {
  margin: 0;
  padding: 0;
  list-style: none;
  .address-item {
    margin-bottom: 10px;
    .ai-name {
      width: 150px;
      display: inline-block;
    }
    .ai-phone {
      width: 120px;
      display: inline-block;
    }
    .ai-address {
    }
  }
}
</style>