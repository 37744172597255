<!--
 * @Description: 批量新增弹层
 * @Author: 前胡
 * @LastEditors: 前胡
 * @Date: 2020-03-12 17:03:34
 * @LastEditTime: 2020-04-23 18:21:02
 -->
 <template>
  <div>
    <a-modal
      class="batch-upload-express-dialog"
      title="导入物流信息"
      v-model="batchUploadGoodsDialogVisible"
      centered
      :bodyStyle="{padding:0}"
      :footer="null"
      @cancel="hideImportFile"
    >
      <div class="p-20">
        <a-alert class="mb-10" :message="tip" banner v-if="importStatus ==='init'" />
        <a-form :form="batchUploadGoodsForm" @submit="handleCreateGoodsFormSubmit">
          <a-form-item
            label
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 12 }"
            v-if="importStatus ==='init'"
          >
            <a-upload
              name="file"
              accept=".xlsx, .xls"
              :multiple="false"
              :fileList="fileList"
              :beforeUpload="beforeUpload"
              :action="importGoodsUrl"
              :headers="uploadHeaders"
              @change="handleImportGoodsUploadChange"
              :data="importGoodsData"
            >
              <a-button>
                <a-icon type="upload" />选择xls文件上传
              </a-button>
            </a-upload>
          </a-form-item>
          <a-form-item v-if="importStatus ==='init'">
            <a :href="expressImportTemplateUrl" target="_blank">下载模板</a>
          </a-form-item>
          <a-form-item v-show="importStatus ==='uploading'">
            <div class="upload-spin">
              <a-spin size="large" />
            </div>
          </a-form-item>
          <a-form-item v-if="importStatus ==='done'">
            <div class="upload-res-box">
              <p>
               本次导入{{importSuccessData.total_count}}条物流信息，处理成功{{importSuccessData.success_count}}条，失败{{importSuccessData.wrong_count}}条</p>
            </div>
          </a-form-item>
        </a-form>
      </div>
      <div class="ant-modal-footer">
        <div v-if="importStatus ==='init'">
          <button type="button" class="ant-btn ant-btn-primary" @click="handleUploadGoods">
            <span>上 传</span>
          </button>
        </div>
        <div v-if="importStatus ==='done'">
          <button type="button" class="ant-btn ant-btn-primary" @click="hideImportFile">
            <span>关 闭</span>
          </button>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { baseURL } from "@/config/index";
import { expressImportTemplateUrl } from "@/const/template";
import axios from "@/service/axios";

export default {
  name: "ModalImportExpressFile",
  props: {
    tip: {
      default:
        "已审核通过的待发货订单会更新发货状态，已发货订单会更新物流信息，其他状态订单信息不会变更"
    }
  },
  data() {
    return {
      batchUploadGoodsDialogVisible: true,
      fileList: [],
      importSuccessData: {},
      importStatus: "init",
      isUplodSuccess: false,
      expressImportTemplateUrl: `${expressImportTemplateUrl}?t=${new Date().getTime()}`
    };
  },
  methods: {
    // 隐藏批量新增弹层
    hideImportFile() {
      this.$emit("callback", this.isUplodSuccess);
    },
    // 选择文件上传修改
    beforeUpload(file) {
      this.fileList = [file];
      return false;
    },
    // 手动上传
    handleUploadGoods() {
      const { fileList } = this;
      if (!fileList.length) {
        this.$message.error(`请选择文件`);
        return false;
      }
      const formData = new FormData();
      formData.append("file", fileList[0]);
      this.importStatus = "uploading";
      axios({
        method: "post",
        url: `${baseURL}/api/operators/v1/express/upload-file/`,
        data: formData
      })
        .then(response => {
          const { err, res } = response;
          if (!err) {
            if (res.success) {
              // this.$message.success(`${res.message}`);
              this.importSuccessData = res.data;
              this.importStatus = "done";
              this.isUplodSuccess = true;
              // this.hideImportFile(true);
            } else {
              // 认证信息不对等错误
              // this.$message.error(`${res.message}`);
              this.importStatus = "init";
              this.isUplodSuccess = false;
            }
          } else {
            this.importStatus = "init";
            this.isUplodSuccess = false;
          }
        })
        .catch(error => {
          this.$message.error(`${error}`);
          this.importStatus = "init";
          this.isUplodSuccess = false;
        });
    }
  }
};
</script>
<style lang="less" scoped>
.p-20 {
  padding: 20px;
}
.mb-10 {
  margin-bottom: 10px;
}
.upload-spin {
  text-align: center;
}
</style>