<!--
 * @Description: 地区选择
 * @Author: 前胡
 * @LastEditors: 前胡
 * @Date: 2020-03-13 14:16:04
 * @LastEditTime: 2020-03-14 11:08:29
 -->
 <template>
  <div class="cp-bar">
    <!--省-->
    <a-select :value="pickedProvinceId" class="select-item" @change="handleChangeProvince">
      <a-select-option :value="p[0]" v-for="p in provinceList" :key="p[0]">{{p[1]}}</a-select-option>
    </a-select>
    <!--市-->
    <a-select class="select-item" @change="handleChangeCity" :value="pickedCityId">
      <a-select-option
        :value="p[0]"
        v-for="p in cityDict[this.pickedProvinceId].cities"
        :key="p[0]"
      >{{p[1]}}</a-select-option>
    </a-select>
    <!--区-->
    <a-select class="select-item" @change="handleChangeArea" :value="pickedAreaId">
      <a-select-option
        :value="p[0]"
        v-for="p in areaDict[this.pickedCityId].districts"
        :key="p[0]"
      >{{p[1]}}</a-select-option>
    </a-select>
  </div>
</template>
<script>
import { provinceList, cityDict, areaDict } from "@/const/nation";
export default {
  name: "CityPicker",
  props: {
    cityData: {
      default: function() {
        return {
          provinceId: 110000,
          cityId: 110000,
          areaId: 110101
        };
      }
    }
  },
  data() {
    return {
      provinceList,
      cityDict,
      areaDict,
      pickedProvinceId: this.cityData.provinceId,
      pickedCityId: this.cityData.cityId,
      pickedAreaId: this.cityData.areaId
    };
  },
  methods: {
    // 获取中文名称
    getChineseByCode(code, list) {
      const areaArr = list.filter(i => i[0] === code);
      const str = areaArr[0][1];
      return str;
    },
    // 改变省
    handleChangeProvince(e) {
      this.pickedProvinceId = e;
      this.pickedCityId = this.cityDict[e].cities[0][0];
      this.pickedAreaId = this.areaDict[this.pickedCityId].districts[0][0];
      this.formatAreaData();
    },
    // 改变市
    handleChangeCity(e) {
      this.pickedCityId = e;
      this.pickedAreaId = this.areaDict[e].districts[0][0];
      this.formatAreaData();
    },
    // 改变区
    handleChangeArea(e) {
      this.pickedAreaId = e;
      this.formatAreaData();
    },
    // 处理值
    formatAreaData() {
      const provinceStr = this.getChineseByCode(
        this.pickedProvinceId,
        provinceList
      );
      const cityStr = this.getChineseByCode(
        this.pickedCityId,
        cityDict[this.pickedProvinceId].cities
      );
      const areaStr = this.getChineseByCode(
        this.pickedAreaId,
        areaDict[this.pickedCityId].districts
      );
      const data = {
        provinceId: this.pickedProvinceId,
        cityId: this.pickedCityId,
        areaId: this.pickedAreaId,
        provinceStr: provinceStr,
        cityStr: cityStr,
        areaStr: areaStr
      };
      this.$emit('callback', data)
    }
  }
};
</script>
<style lang="less" scoped>
.cp-bar {
  width: 450px;
  .select-item {
    width: 130px;
    margin-right: 10px;
  }
}
</style>