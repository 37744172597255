var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-other-address-modal"},[_c('a-modal',{attrs:{"title":"其他地址","width":"600px"},on:{"cancel":_vm.handleHideThisModal},model:{value:(_vm.isVisable),callback:function ($$v) {_vm.isVisable=$$v},expression:"isVisable"}},[_c('a-form',{attrs:{"form":_vm.userAddressForm}},[_c('a-form-item',{attrs:{"label":"收货人","label-col":{ span: 5 },"wrapper-col":{ span: 12 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['postData.realname', { rules: [{ required: true, message: '请输入收货人' }] }]),expression:"['postData.realname', { rules: [{ required: true, message: '请输入收货人' }] }]"}],staticStyle:{"width":"150px"}})],1),_c('a-form-item',{attrs:{"label":"手机","label-col":{ span: 5 },"wrapper-col":{ span: 12 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'postData.mobile',
              {
                rules: [
                  { required: true, message: '请输入手机号' },
                  { validator: _vm.checkPhoneNumber }
                ],
                validateFirst: true
              }
            ]),expression:"[\n              'postData.mobile',\n              {\n                rules: [\n                  { required: true, message: '请输入手机号' },\n                  { validator: checkPhoneNumber }\n                ],\n                validateFirst: true\n              }\n            ]"}],staticStyle:{"width":"150px"}})],1),_c('a-form-item',{attrs:{"label":"详细地址","label-col":{ span: 5 },"wrapper-col":{ span: 12 }}},[_c('CityPicker',{attrs:{"cityData":_vm.cityData},on:{"callback":_vm.handleChangeAddress}}),_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['postData.address', { rules: [{ required: true, message: '请输入详细地址' }] }]),expression:"['postData.address', { rules: [{ required: true, message: '请输入详细地址' }] }]"}],attrs:{"autosize":{ minRows: 2, maxRows: 6 }}})],1)],1),_c('template',{slot:"footer"},[_c('a-button',{key:"back",on:{"click":_vm.handleHideThisModal}},[_vm._v("取消")]),_c('a-button',{key:"submit",attrs:{"type":"primary","loading":_vm.isPosting},on:{"click":_vm.handleSubmitOtherAddrData}},[_vm._v("确定")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }