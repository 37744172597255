<!--
 * @Description: 修改收货地址弹层
 * @Author: 前胡
 * @LastEditors: 前胡
 * @Date: 2020-03-13 10:56:09
 * @LastEditTime: 2020-03-18 16:06:35
 -->
 <template>
  <div class="address-list-modal">
    <a-modal title="修改收货地址" width="1000px" v-model="isVisable" @ok="handleOk" @cancel="hideThisModal">
      <a-radio-group @change="handleChangeAddress" v-model="defaultId">
        <ul class="address-list">
          <li class="address-item" v-for="i in list" :key="i.id">
            <a-radio :style="radioStyle" :value="i.id">
              <span class="ai-name">{{i.realname}}</span>
              <span class="ai-phone">{{i.mobile}}</span>
              <span class="ai-address">{{i.province}}{{i.city}}{{i.area}}{{i.address}}</span>
            </a-radio>
          </li>
          
        </ul>
      </a-radio-group>
      <div>
        <a-button size="small" @click="handleShowOtherAddrModal">其他地址</a-button>
      </div>
    </a-modal>
    <UserOtherAddressModal v-if="otherAddressModalVisiable" @callback="handleHideOtherAddress" :id="id" :sampleid="sampleid" />
  </div>
</template>
<script>
import { getAddressList } from "@/service/user";
import { patchSample } from "@/service/sample";
import UserOtherAddressModal from "@/components/admin/sample/user-other-address-modal";
export default {
  name: "AddressListModal",
  components:{
      UserOtherAddressModal
  },
  props: {
    id: {
      default: ""
    },
    sampleid: {
      default: ""
    }
  },
  data() {
    return {
      isVisable: true,
      otherAddressModalVisiable: false,
      list: [],
      defaultId: null,
      userAddressForm: this.$form.createForm(this)
    };
  },
  created() {
    this.getAddressListData();
  },
  methods: {
    // 获取地址列表
    async getAddressListData() {
      const { err, res } = await getAddressList({ user: this.id });
      if (!err) {
        if (res.success) {
          this.list = res.data.results;
          this.getDefaultId(res.data.results);
        }
      }
    },
    // 获取默认选中id
    getDefaultId(list) {
      const defaultIds = list.filter(i => i.is_default === true);
      const defaultId = defaultIds[0] && defaultIds[0].id;
      this.defaultId = defaultId;
    },
    handleChangeAddress(e) {
      this.defaultId = e.target.value
    },
    // 提交修改数据
    async handleOk() {
      if (this.defaultId) {
          const data = {
              address_key: this.defaultId
          }
          const { err, res } = await patchSample(this.sampleid, data);
          if(!err){
            if(res.success){
              this.$message.success(res.message);
              this.hideThisModal(true)
            }
          }
      }
    },
    // 显示另外地址弹层
    handleShowOtherAddrModal(){
        this.otherAddressModalVisiable=true;
    },
    // 隐藏弹层
    hideThisModal(isNeedRefresh=false){
      this.$emit('callback', isNeedRefresh)
    },
    // 隐藏其他地址
    handleHideOtherAddress(needHideThisModal){
      this.otherAddressModalVisiable = false
      if(needHideThisModal){
        this.hideThisModal(true)
      }
    }
  }
};
</script>
<style lang="less" scoped>
.address-list {
  margin: 0;
  padding: 0;
  list-style: none;
  .address-item {
    margin-bottom: 10px;
    .ai-name {
      width: 150px;
      display: inline-block;
    }
    .ai-phone {
      width: 120px;
      display: inline-block;
    }
    .ai-address {
    }
  }
}
</style>