<!--
 * @Author: 前胡
 * @LastEditors: 胡椒
 * @Date: 2020-03-05 13:36:09
 * @LastEditTime: 2020-08-24 11:56:40
 * @Description: 寄样申请审核弹层
 -->
<template>
  <div>
    <a-modal title="寄样申请审核" :visible="true" centered @cancel="closeModal">
      <a-form :form="sampleAuditForm">
        <a-form-item
          label="审核结果"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 12 }"
        >
          <a-select
            placeholder="请选择"
            v-decorator="['examine_status', descriptor.examine_status]"
            @change="handleChangeAuditStatus"
          >
            <a-select-option :value="2">通过</a-select-option>
            <a-select-option :value="3">未通过</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="原因"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 12 }"
          v-if="examine_status === 3"
        >
          <a-textarea
            v-decorator="['examine_remark', descriptor.examine_remark]"
            placeholder="请填写拒绝原因，会直接展示给用户，注意文案规范"
            :autosize="{ minRows: 4, maxRows: 6 }"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <a-button key="back" @click="handleHideThisModal">取消</a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="isPosting"
          @click="handleSubmitAudit"
          >确定</a-button
        >
      </template>
    </a-modal>
  </div>
</template>
<script>
import { patchSample } from '@/service/sample';

const descriptor = {
  examine_status: { rules: [{ required: true, message: '请填写审核结果' }] },
  examine_remark: { rules: [{ required: true, message: '请填写拒绝原因' }] },
};

export default {
  name: 'ModalSampleApplyAudit',
  props: {
    id: {
      default: '',
    },
  },
  data() {
    return {
      isPosting:false,
      sampleAuditForm: this.$form.createForm(this),
      examine_status: null,
      examine_remark: '',
      descriptor,
    };
  },
  methods: {
    closeModal() {
      this.$emit('callback');
    },
    // 修改状态
    handleChangeAuditStatus(e) {
      this.examine_status = e;
    },
    // 寄样审核提交数据
    handleSubmitAudit() {
      this.sampleAuditForm.validateFields(async (err, values) => {
        if (!err) {
          const data = values;
          this.isPosting = true;
          const { err, res } = await patchSample(this.id, data);
          this.isPosting = false;
          if (!err) {
            this.$message.success(res.message);
            this.handleHideThisModal(true);
          }
        }
      });
    },
    // 隐藏modal
    handleHideThisModal(isNeedParent = false) {
      this.$emit('callback', isNeedParent);
    },
  },
};
</script>
<style lang="less" scope></style>
