<!--
 * @Author: 前胡
 * @LastEditors: 矢车
 * @Date: 2020-03-04 17:18:43
 * @LastEditTime: 2020-11-05 16:50:01
 * @Description: 寄样详情
 -->
<template>
  <div id="applyDetail">
    <div v-if="!loadingDetailData">
      <a-card class="mb-20" size="small">
        <div slot="title">
          <div class="app-d-list">
            <div>
              <div v-if="applyDetailData.examine_status!==3" class="mr-20 tl">
                申请单状态：
                <!-- 支付状态 -->
                <a-tag v-if="applyDetailData.is_buy_order===1" :color="payStatus.color || 'red'">{{payStatus.label || '--'}}</a-tag>
                <!-- 发货状态 -->
                <a-tag v-if="applyDetailData.send_status===1" color="purple">待发货</a-tag>
                <a-tag v-if="applyDetailData.send_status===2" color="blue">已发货</a-tag>
                <a-tag v-if="applyDetailData.send_status===3" color="green">已收货</a-tag>
                <a-tag v-if="applyDetailData.send_status===4" color="orange">已取消</a-tag>
                <a-tag v-if="applyDetailData.send_status===5" color="red">未通过</a-tag>
                <a-tag v-if="applyDetailData.send_status===6" color="#2db7f5">待寄回</a-tag>
                <a-tag v-if="applyDetailData.send_status===7" color="cyan">已寄出</a-tag>
                <a-tag v-if="applyDetailData.send_status===8" color="purple">已回收</a-tag>

                <a-button v-if="applyDetailData.is_buy_order===1 && applyDetailData.pay_status===2 && applyDetailData.send_status===1" size="small" type="primary"  style="font-size: 12px;" @click="isOpenCancelOrder=true;">取消订单</a-button>
                <a-modal v-model="isOpenCancelOrder" title="请注意" @ok="cancelOrderConfirm" ok-text="继续" cancel-text="关闭">
                  <p>请先和商家协商确认未发货再取消，如订单内的商品涉及到多个供货商，请全部进行确认。该功能目前仅改变订单状态，不涉及订单拦截及自动退款，取消后通过人工方式给用户进行退款。取消操作不可逆，谨慎</p>
                </a-modal>

                <a-modal v-model="isOpenOrder" title="取消订单" @ok="OrderConfirm" ok-text="确认取消" cancel-text="关闭">
                  <p>确定取消该订单?</p>
                  <textarea v-model="orderCancelReason" name="" id="" cols="30" rows="5" placeholder="请填写取消原因,必填" style="padding: 10px;border-radius: 4px;width: 100%;"></textarea>
                </a-modal>

                <div v-if="applyDetailData.examine_remark" style="margin-left: 20px;">取消原因：{{applyDetailData.examine_remark}}</div>
              </div>
              <div class="tl mt10">
                <div class="mr-20">
                  直播类型：
                  <a-tag v-if="applyDetailData.apply_type===1" color="blue">拼播</a-tag>
                  <a-tag v-if="applyDetailData.apply_type===2" color="cyan">单播</a-tag>
                  <span v-if="applyDetailData.apply_type===3">
                    <a-tag color="blue">专场</a-tag>
                    <span v-if="applyDetailData.special">
                      <span>专场ID: {{ applyDetailData.special }}</span> -
                      <span class="special-title" :title="applyDetailData.special__title">
                        {{ applyDetailData.special__title.length > 18?applyDetailData.special__title.slice(0, 18) + '...': applyDetailData.special__title }}
                      </span>
                    </span>
                  </span>
                </div>
                <div class="mr-20" v-if="applyDetailData.apply_type===1">拼播ID：{{applyDetailData.live_group_id}}</div>
                <div class="cancel-reason" v-if="applyDetailData.examine_status===3">
                  <span class="label">取消原因：</span>
                  <span
                    v-if="applyDetailData.examine_status===3"
                    class="value"
                    style="color:red"
                    :title="applyDetailData.examine_remark"
                  >{{ applyDetailData.examine_remark }}</span>
                </div>
              </div>
            </div>
            <div class="tr">
              <a-button
                v-if="applyDetailData.send_status === 1 && applyDetailData.examine_status===2"
                @click="handleShowCancelApplyModal"
                type="primary"
              >取消申请</a-button>
              <a-button @click="handleConfirmReceipt" v-if="applyDetailData.send_status === 7" type="primary">确认收货</a-button>
            </div>
          </div>
        </div>
        <div>
          <ul class="sample-info-b">
            <li>
              <dl class="apply-user">
                <dt>申请人</dt>
                <dd class>
                  <div class="au-user">{{applyDetailData.user__name}}</div>
                  <div class="au-mobile">手机：{{applyDetailData.user__mobile}}</div>
                  <div class="au-channel">
                    渠道：
                    <span v-if="applyDetailData.user__channel ===0">无</span>
                    <span v-if="applyDetailData.user__channel ===2">淘宝</span>
                    <span v-if="applyDetailData.user__channel ===1">抖音</span>
                  </div>
                  <div class="au-nickname">
                    主播昵称:
                    <span
                      v-if="applyDetailData.user__channel ===1"
                    >{{applyDetailData.user__douyin_nickname}}</span>
                    <span
                      v-if="applyDetailData.user__channel ===2"
                    >{{applyDetailData.user__anchor_name}}</span>
                  </div>
                  <div class="au-lv">
                    主播等级：
                    <span>{{ applyDetailData.level?'LV' + applyDetailData.level:'-' }}</span>
                  </div>
                </dd>
              </dl>
            </li>
            <li>
              <dl class="apply-user">
                <dt>收货人</dt>
                <dd>
                  <div class="au-user">{{applyDetailData.realname}}</div>
                  <div class="au-mobile">手机：{{applyDetailData.mobile}}</div>
                  <div class="au-addr">
                    地址：{{applyDetailData.province}}{{applyDetailData.city}}{{applyDetailData.area}}{{applyDetailData.address}}
                    <a-button
                      v-if="applyDetailData.is_buy_order===0 && applyDetailData.examine_status===2 && applyDetailData.send_status===1"
                      size="small"
                      @click="handleShowEditAddrModal"
                      class="ml-20"
                    >修改地址</a-button>
                    <a-button
                      v-if="applyDetailData.is_buy_order===1 && applyDetailData.send_status===1"
                      size="small"
                      @click="handleShowEditAddrModal(true)"
                      class="ml-20"
                    >修改地址</a-button>
                    <a-modal v-model="isLogisticsModel" title="请注意" @ok="isLogisticsModel=false;editAddrModalVisible = true" ok-text="继续" cancel-text="关闭">
                      <p>请和商家沟通确认，本功能不会去校验订单在商家那边处于什么阶段，即使商家已发货这边依然可以修改成功</p>
                    </a-modal>
                  </div>
                </dd>
              </dl>
            </li>
          </ul>
        </div>
      </a-card>
      <a-card title="商品信息" class="mb-20" size="small">
        <table class="n-table">
          <thead>
            <tr>
              <th width="230">商品名称</th>
              <th width="100">商品ID</th>
              <th width="100">SKU编码</th>
              <th width="100">规格</th>
              <th width="80">数量</th>
              <th width="80" v-if="applyDetailData.is_buy_order===1">采样价</th>
              <th width="100">供应商</th>
              <th width="100">供应商货号</th>
              <th width="100">招商对接人</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item_goods in applyDetailData.sample_goods" :key="item_goods.item_id">
              <td class="flex_align">
                <img v-if="applyDetailData.is_buy_order===1" :src="goodsTypeOptionsMethods(item_goods.user_type)" alt="">
                {{item_goods.good_title}}
              </td>
              <td>{{item_goods.item_id}}</td>
              <td>{{item_goods.sku_code}}</td>
              <td>{{item_goods.spec_str}}</td>
              <td>{{item_goods.good_count}}</td>
              <td v-if="applyDetailData.is_buy_order===1">{{item_goods.sale_price?'￥':''}}{{item_goods.sale_price}}</td>
              <td>{{item_goods.supplier}}</td>
              <td>{{item_goods.supplier_item_id}}</td>
              <td>{{item_goods.docking_user__username}}</td>
            </tr>
          </tbody>
          <div class="order_money" v-if="applyDetailData.is_buy_order===1">订单金额：￥{{applyDetailData.order_price}}</div>
          <div class="associ_goods" v-if="applyDetailData.is_buy_order===1 && applyDetailData.related_goods.length>0">关联商品</div>
          <div v-else style="margin-bottom: 45px;"></div>
          <tbody v-if="applyDetailData.is_buy_order===1 && applyDetailData.related_goods.length>0">
            <tr class="flex_align" v-for="item_goods in applyDetailData.related_goods" :key="item_goods.item_id">
              <td>
                <img v-if="applyDetailData.is_buy_order===1" :src="goodsTypeOptionsMethods(item_goods.user_type)" alt="">
                {{item_goods.good_title}}
              </td>
              <td>{{item_goods.item_id}}</td>
              <td>{{item_goods.sku_code}}</td>
              <td>{{item_goods.spec_str}}</td>
              <td>{{item_goods.good_count}}</td>
              <td v-if="applyDetailData.is_buy_order===1">{{item_goods.sale_price?'￥':''}}{{item_goods.sale_price}}</td>
              <td>{{item_goods.supplier}}</td>
              <td>{{item_goods.supplier_item_id}}</td>
              <td>{{item_goods.docking_user__username}}</td>
            </tr>
          </tbody>
        </table>
      </a-card>
      <a-card :title="'发货包信息（'+ item.package_no +'）'" size="small" class="mb-20" v-for="item in applyDetailData.packages" :key="item.express_id">
        <div slot="extra">
          <!-- 免费寄样 -->
          <a-button
            class="mr-20"
            v-if="applyDetailData.is_buy_order===0 && applyDetailData.examine_status===2 && (applyDetailData.send_status===1 || applyDetailData.send_status===2)"
            size="small"
            @click="handleShowEditExpressModal(item.id)"
          >编辑物流信息</a-button>
          <!-- 站内采买 -->
          <a-button
            class="mr-20"
            v-if="applyDetailData.is_buy_order===1 && applyDetailData.pay_status===2  && (applyDetailData.send_status===1 || applyDetailData.send_status===2)"
            size="small"
            @click="handleShowEditExpressModal(item.id)"
          >编辑物流信息</a-button>

          <a-button
            v-if="item.express_id && applyDetailData.send_status!==1 && applyDetailData.send_status!==4 && applyDetailData.send_status!==5"
            size="small"
            @click="handleShowExpressDetail('send', item.express_id)"
          >查看物流详情</a-button>
        </div>
        <div>
          <ul class="express-list">
            <li class="el-time">发货方：{{item && item.supplier || '--'}}</li>
            <li class="el-time">物流公司：{{item && item.com_name || '--'}}</li>
            <li class="el-time">快递单号：{{item && item.nu || '--'}}</li>
            <li class="el-time">发货时间：{{item && item.delivery_time || '--'}}</li>
          </ul>
          <table class="n-table mt10">
            <thead>
              <tr>
                <th>商品名称</th>
                <th>商品ID</th>
                <th>SKU编码</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item_package in item.package_goods" :key="item_package.id">
                <td style="width: 300px;">{{item_package && item_package.good_title}}</td>
                <td style="width: 300px;">{{item_package && item_package.item_id}}</td>
                <td style="width: 300px;">{{item_package && item_package.sku_code}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </a-card>
      <a-card title="回寄信息" size="small" v-if="applyDetailData.send_status === 6 || applyDetailData.send_status === 7 || applyDetailData.send_status===8">
        <div slot="extra">
          <a-button
            size="small"
            @click="handleShowExpressDetail('back')"
            v-if="applyDetailData.sendback_express"
          >查看物流详情</a-button>
        </div>
        <div>
          <dl>
            <dt>
              回寄地址:
              <div class="return-info">
                <div style="width:80px">{{applyDetailData.receiver}}</div>
                <div style="width:100px">{{applyDetailData.receiver_mobile}}</div>
                <div style="width:500px">{{applyDetailData.delivery_address}}</div>
              </div>
            </dt>
            <dd>
              <ul class="express-list">
                <li class="el-name">物流公司：{{applyDetailData.sendback_express && applyDetailData.sendback_express.com_name}}</li>
                <li class="el-no">快递单号：{{applyDetailData.sendback_express && applyDetailData.sendback_express.nu}}</li>
                <li class="el-time">发货时间：{{applyDetailData.user_delivery_time}}</li>
              </ul>
            </dd>
          </dl>
        </div>
      </a-card>
      <a-card v-if="applyDetailData.is_buy_order===1 && (applyDetailData.pay_status===2 || applyDetailData.pay_status===5)" title="支付信息" class="mb-20" size="small">
        <table class="n-table">
          <thead>
            <tr>
              <th width="150">支付流水号</th>
              <th width="100">支付人</th>
              <th width="100">支付方式</th>
              <th width="100">支付金额</th>
              <th width="100">支付状态</th>
              <th width="120">支付时间</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{applyDetailData.pay_order.transaction_id}}</td>
              <td>{{applyDetailData.pay_order.wx_user_id}}</td>
              <td>{{applyDetailData.pay_order.pay_type===1?'微信支付':'支付宝支付'}}</td>
              <td>￥{{applyDetailData.pay_order.pay_price}}</td>
              <td>{{payStatus.label}}</td>
              <td>{{applyDetailData.pay_order.pay_time}}</td>
            </tr>
          </tbody>
        </table>
      </a-card>
    </div>
    <div v-if="loadingDetailData">
      <a-spin size="large" />
    </div>
    <!--取消申请弹层-->
    <CancelApplyModal
      v-if="cancelSampleApplyModalVisible"
      :id="id"
      @callback="handleHideCancelApplyModal"
    />
    <!--编辑物流弹层-->
    <ApplyExpressModal v-if="editExpressModalVisible" :data="applyDetailData.send_express" :id="package_id" @callback="hideApplyExpressModal" />
    <!--修改收货地址弹层-->
    <AddressListModal
      v-if="editAddrModalVisible"
      :id="applyDetailData.user"
      :sampleid="id"
      @callback="hideAddressListModal"
    />
    <!--物流详情弹层-->
    <ExpressDetailModal
      v-if="expressDetailVisable"
      :id="tempExpressDetailId"
      @callback="hideExpressDetailModal"
    />
  </div>
</template>
<script>
import { checkPhoneNumber } from "@/utils/validator";
import { getSampleDetail, expressCheck, patchSample } from "@/service/sample";
import AddressListModal from "@/components/admin/sample/address-list-modal";
import ApplyExpressModal from "@/components/admin/sample/apply-express-modal";
import CancelApplyModal from "@/components/admin/sample/cancel-apply-modal";
import ExpressDetailModal from "@/components/admin/sample/express-detail-modal";
import { payStatusOptions, goodsTypeOptions } from "@/const/goods-cate"

export default {
  name: "ApplyDetail",
  components: {
    AddressListModal,
    ApplyExpressModal,
    CancelApplyModal,
    ExpressDetailModal
  },
  props: {
    id: {
      default: ""
    }
  },
  created() {
    this.getSampleDetailData();
  },
  data() {
    return {
      payStatusOptions,  //  支付状态枚举
      goodsTypeOptions,
      checkPhoneNumber,
      // 取消寄样申请弹层标记
      cancelSampleApplyModalVisible: false,
      // 修改收货地址弹层标记
      editAddrModalVisible: false,
      // 编辑物流弹层
      editExpressModalVisible: false,
      radioStyle: {
        display: "block"
      },
      // 获取寄样详情数据
      loadingDetailData: false,
      // 寄样详情数据
      applyDetailData: {},
      // 物流详情
      tempExpressDetailId: null,
      expressDetailVisable: false,
      // 编辑物流信息
      tempExpressData:null,
      isOpenCancelOrder: false,  // 点击取消订单出现的请注意弹框
      isOpenOrder: false, // 取消订单确认框
      orderCancelReason: "",  // 取消原因
      isLogisticsModel: false,   // 站内采买订单 修改地址 按钮
      package_id: null,  // 点击编辑物流信息传过去的 pakage_id
    };
  },
  computed: {
    // 支付状态
    payStatus: function() {
      for(const val of this.payStatusOptions){
        if(val.value == this.applyDetailData.pay_order.pay_status){
          return val
        }
      }
      return ''
    }
  },
  methods: {

    /**
     * @Author: 矢车
     * @Date: 2020-10-17 10:31:56
     * @Description: 商品类型小图标标识
    */
    goodsTypeOptionsMethods(value){
      for(const val of this.goodsTypeOptions){
        if(val.value == value){
          return val.src
        }
      }
      return ''
    },

    /**
     * @Author: 矢车
     * @Date: 2020-10-16 14:17:06
     * @Description: 点击取消订单继续按钮
    */
    cancelOrderConfirm(){
      this.isOpenCancelOrder = false;
      this.isOpenOrder = true;
    },

    /**
     * @Author: 矢车
     * @Date: 2020-10-16 14:31:04
     * @Description: 点击取消订单确认取消按钮
    */    
    async OrderConfirm(){
      if(this.orderCancelReason){
        const { err, res } = await patchSample(this.id, {
          examine_remark: this.orderCancelReason,
          send_status: 4
        });
        if (!err) {
          this.$message.success(res.message);
          this.isOpenOrder = false;
          this.getSampleDetailData();
        }
      }else{
        this.$message.error("请填写取消原因")
      }
    },

    // 显示取消申请弹层
    handleShowCancelApplyModal() {
      this.cancelSampleApplyModalVisible = true;
    },
    // 隐藏取消申请弹层
    handleHideCancelApplyModal(needFreshThis = false) {
      this.cancelSampleApplyModalVisible = false;
      if (needFreshThis) {
        this.getSampleDetailData();
      }
    },
    // 显示修改地址弹层
    handleShowEditAddrModal(isType) {
      //isType=true代表是站内采买
      if(isType){
        this.isLogisticsModel = true;
        return
      }
      this.editAddrModalVisible = true;
    },
    // 隐藏修改地址弹层
    hideAddressListModal(needFreshThis = false) {
      this.editAddrModalVisible = false;
      if (needFreshThis) {
        this.getSampleDetailData();
      }
    },
    // 隐藏物流信息弹层
    hideApplyExpressModal(needFreshThis = false) {
      this.editExpressModalVisible = false;
      if (needFreshThis) {
        this.getSampleDetailData();
      }
    },
    // 显示物流信息弹层
    handleShowEditExpressModal(temp_id) {
      this.package_id = temp_id;
      this.editExpressModalVisible = true;
    },
    // 显示其他地址弹层标记
    handleShowOtherAddrModal() {
      this.ohterAddrModalVisible = true;
      this.otherAddrForm.resetFields();
    },

    // 保存其他地址数据
    handleSubmitOtherAddrData(e) {
      e.preventDefault();
      this.otherAddrForm.validateFields((err, values) => {
        if (!err) {
          this.doLogin(values);
        }
      });
    },
    // 获取寄样申请详情
    async getSampleDetailData() {
      this.loadingDetailData = true;
      const { err, res } = await getSampleDetail(this.id);
      this.loadingDetailData = false;
      if (!err) {
        if (res.success) {
          this.applyDetailData = res.data;
        }
      }
    },
    // 显示物流详情
    handleShowExpressDetail(type = "send",express_id) {
      const id =
        type === "send"
          ? express_id
          : this.applyDetailData.sendback_express &&
            this.applyDetailData.sendback_express.id;
      this.tempExpressDetailId = id;
      this.expressDetailVisable = true;
    },
    // 隐藏物流详情
    hideExpressDetailModal() {
      this.expressDetailVisable = false;
    },
    // 确认收货
    handleConfirmReceipt() {
      const that = this;
      this.$confirm({
        title: "确认收货",
        content: () => <div>确认已收到用户寄回的样品？</div>,
        async onOk() {
          const { err, res } = await expressCheck({ id: that.id });
          if (!err && res.success) {
            that.$message.success(res.message);
            that.getSampleDetailData();
          }
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.packageTr{
  th {
    min-width: 292px;
    max-width: 292px;
  }
}
.flex_align {
  img{
    width: 28px;
    height: 15px;
    position: relative;
    margin-top: -3px;
  }
}
.sample-info-b {
  margin: 0;
  padding: 0 0;
  list-style: none;
  li {
    margin-bottom: 5px;
  }
}
.order_money{
  position: absolute;
  right: 10px;
  margin: 15px 0;
}
.associ_goods{
  margin-top: 40px;
  margin-bottom: 10px;
  font-weight: bold;
}
.mb-20 {
  margin-bottom: 20px;
}
.ml-20 {
  margin-left: 20px;
}
.n-table {
  width: 100%;
  th,td {
    padding: 5px;
    border: 1px solid #eee;
    min-height: 35px;
  }
  th {
    background: #f5f5f5;
  }
}
.app-d-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .tl {
    display: flex;
    flex-wrap: wrap;
  }
  .tr {
  }
}
.apply-user {
  margin-bottom: 10px;
  border: 1px solid #eee;
  dt {
    margin-bottom: 5px;
    border-bottom: 1px solid #eee;
    background: #f5f5f5;
    padding-left: 5px;
  }
  dd {
    padding: 5px;
    display: flex;
    .au-user {
      width: 100px;
    }
    .au-mobile {
      width: 160px;
    }
    .au-channel {
      width: 150px;
    }
    .au-lv {
      margin-left: 30px;;
    }
    .au-addr {width: 480px;}
  }
}
.express-list {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  .el-name {
    width: 220px;
  }
  .el-no {
    width: 250px;
  }
  .el-time {
    width: 220px;
  }
}
.return-info {
  font-weight: normal;
  display: flex;
  margin-bottom: 20px;
  div {
    margin-right: 30px;
  }
}
.cancel-reason {
  display: flex;
  .value {
    width: 700px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
}
</style>
